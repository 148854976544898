.tender-navbar {
    padding: 0.5rem 1rem;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-left: 8rem;

    .navbar-brand {
        font-weight: bold;
        color: #333;
    }

    .nav-item {
        margin-right: 0.5rem;
    }

    .nav-btn {
        background-color: transparent;
        border: 1px solid #dee2e6;
        color: #333;
        padding: 0.375rem 0.75rem;
        font-size: 0.9rem;

        &:hover {
            background-color: #e9ecef;
        }

        .fa-icon {
            margin-right: 0.25rem;
        }
    }

    .icon-pink {
        color: #ff69b4;
    }

    .subscribe-btn {
        background-color: #ffc107;
        border-color: #ffc107;
        color: #212529;
        font-weight: bold;

        &:hover {
            background-color: #e0a800;
            border-color: #d39e00;
        }
    }

    .badge {
        background-color: #343a40;
        color: white;
        border-radius: 50%;
        padding: 0.25em 0.5em;
        font-size: 0.75em;
        margin-left: 0.5em;
    }

    .profile-btn {
        position: relative;
        padding-right: 20px;
        font-weight: 600;
    }

    .profile-dropdown {
        position: relative;

        .dropdown-toggle {
            display: flex;
            align-items: center;
        }

        .profile-menu {
            background-color: white;
            border-radius: 12px;
            padding: 10px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);

            a {
                color: black;
                text-decoration: none;
                display: block;
                padding: 8px 16px;
                border-radius: 8px;

                &:hover {
                    background-color: #f1f1f1;
                }
            }
        }
    }
}