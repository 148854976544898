.footer {
  background-color: #122040;
  padding: 30px 0;
  color: #ffffff;

  .footer-container {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 1rem 0 5rem;
  }

  .logo {
    h2 {
      font-size: 24px;
      font-weight: bold;

      span {
        color: #fff100; // Matching the yellow color in the logo
      }
    }

    p {
      font-size: 14px;
      margin-top: 10px;
      line-height: 1.5;
    }
  }

  h5 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  .footer-logo {
    p {
      margin-top: 5px;
    }
  }

  .footer-menu,
  .footer-contact,
  .footer-policies,
  .footer-social {
    ul {
      li {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .footer-bottom {
    background-color: #122040;
    // padding: 20px 0;
    margin-top: 1.5rem;
    color: #ffffff;

    .footer-qr {
      // text-align: center;

      .play-store {
        max-width: 120px;
        margin-bottom: 10px;
      }

      .qr-code {
        max-width: 100px;
      }
    }

    .footer-location {
      h5 {
        font-size: 16px;
        font-weight: bold;
      }

      p {
        font-size: 14px;
        margin-top: 10px;
      }
    }

    .footer-partnerships {
      h5 {
        font-size: 16px;
        font-weight: bold;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;
          font-size: 14px;
        }
      }

    }

    .partners-logos {
      img {
        max-width: 50px;
        margin: 5px;
      }
    }

    .footer-legal {
      margin: 2rem 0;
      display: flex;
      align-items: center;

      .globe-icon {
        max-width: 50px;
        margin-right: 15px;
      }

      .footer-company {

        h5 {
          font-size: 16px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          margin: 0;
        }

        strong {
          font-size: 14px;
          margin-top: 5px;
          display: block;
        }
      }
    }

    .footer-security {
      text-align: center;
      display: flex;

      p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 15px;
      }

      .payment-logos {
        width: auto;
        height: 40px;
        max-width: 100%;
        margin-bottom: 10px;
      }
    }
  }

}